import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-raritychart',
  templateUrl: './raritychart.component.html',
  styleUrls: ['./raritychart.component.scss']
})
export class RaritychartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
